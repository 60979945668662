<template>
    <div class="pageContainer" >
        <img src="../assets/home/goblin.png" id="still" ref="g" @click="burp" alt="logo">
        <img src="../assets/home/goblin.webp" style="display:none;" id="target" alt="logo">
      <h3 class="welcomeMessage" ref="w">welcome to my website</h3>
      <div class="tileContainer" ref="container">
        <div ref="s"><router-link to="/software" class="tile">software</router-link></div>
        <div ref="p"><router-link to="/projects" class="tile">projects</router-link></div>
        <div ref="a"><router-link to="/about" class="tile">about</router-link></div>
        <div ref="c"><router-link to="/contact" class="tile">contact</router-link></div>
      </div>
    </div>
</template>

<script>
import { tween, styler} from 'popmotion';
export default {
  name: 'Home',
  components: {
  },
  mounted() {
    let g = styler(this.$refs.g)
    let w = styler(this.$refs.w)
    let s = styler(this.$refs.s)
    let a = styler(this.$refs.a)
    let p = styler(this.$refs.p)
    let c = styler(this.$refs.c)

    tween({
      from: {
        opacity: 0,
      },
      to: {
        opacity: 1,
      },
      elapsed: -300,
      duration: 1000,
    }).start(g.set),

    tween({
      from: {
        opacity: 0,
      },
      to: {
        opacity: 1,
      },
      elapsed: -600,
      duration: 1000,
    }).start(w.set),

    tween({
      from: {
        opacity: 0,
        y: '200px',
      },
      to: {
        opacity: 1,
        y: '0px',
      },
      elapsed: -900,
      duration: 1000,
    }).start(s.set),

    tween({
      from: {
        opacity: 0,
        y: '200px',
      },
      to: {
        opacity: 1,
        y: '0px',
      },
      elapsed: -1100,
      duration: 1000,
    }).start(p.set),

    tween({
      from: {
        opacity: 0,
        y: '200px',
      },
      to: {
        opacity: 1,
        y: '0px',
      },
      elapsed: -1300,
      duration: 1000,
    }).start(a.set),

    tween({
      from: {
        opacity: 0,
        y: '200px',
      },
      to: {
        opacity: 1,
        y: '0px',
      },
      elapsed: -1500,
      duration: 1000,
    }).start(c.set)

  },
  methods: {
    burp: function() {
      // need to have a hidden element to see what the actual src attribute will be since Vue adds a unique number when compiling
      let renderedElement = document.getElementById('target')
      document.getElementById('still').setAttribute('src', renderedElement.src)
      console.log('%clogo and animation by', 'font-weight: bold; font-size: 20px;color: white;');
      console.log('%cDaley Pedersen', 'font-weight: bold; font-size: 30px;color: white; text-shadow: 3px 3px 0 rgb(226, 177, 230), 5px 5px 0 rgb(225, 145, 230) , 7px 7px 0 rgb(221, 113, 227)');
    }
  }
}
</script>

<style scoped>

#still {
  width: 300px;
  margin-left:30px;
}

.welcomeMessage {
  color: white;
  margin-bottom: 50px;
  /* background-image: linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 50%, rgba(252,176,69,1) 100%);
  padding: 10px 25px;
  border-radius: 5px; */
}

.pageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
}

.tileContainer {
  flex-direction: column;
}

.tile {
  padding: 50px;
  border-radius: 10px;
  background: var(--light-background);
  width: 60vw;
  display: flex;
  justify-content: center;
  color: white;
  text-decoration: none;
  margin: 20px;
  font-size: 1.5rem;
  transition: 500ms;
  box-sizing: border-box;
}

.tile:hover {
  border-radius: 0;
  transform: scale(1.05);
  background: honeydew;
  color: var(--background-color)
}

@media screen and (min-width: 900px) {

  #still {
    margin-top: 15vh;
  }

  .tileContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
  }

  .tile {
    width: 20vw;
  }

}

</style>
