<template>
  <div class="pageContainer">
    <div ref="n">
      <Navbar />
    </div>
    <div class="pageBody" v-cloak>
      <div class="detailsContainer">
        <div class="linkText" ref="e"><font-awesome-icon icon="envelope" class="contactIcon" />austinelwell99@gmail.com</div>
        <div class="linkText" ref="p"><font-awesome-icon icon="phone" class="contactIcon" />512.698.6818</div>
        <a href="https://www.linkedin.com/in/austin-elwell/" target="_blank" class="linkText" ref="l"><font-awesome-icon :icon="[ 'fab', 'linkedin' ]" class="contactIcon" />linkedin</a>
        <a href="https://github.com/austinelwell99" target="_blank" class="linkText" ref="g"><font-awesome-icon :icon="[ 'fab', 'github' ]" class="contactIcon" />github</a>
        <Resume :pose="isVisible ? 'visible' : 'hidden'" href="https://drive.google.com/file/d/1wkwRLYN4bUm0Cfu4BNgmRLN9QYlePJ-a" target="_blank"  class="viewResume">VIEW RÉSUMÉ</Resume>
      </div>
      <div class="picturesDesktop">
        <img src="../assets/contact/daley.jpg" id="daley" ref="daley"/>
        <img src="../assets/contact/lucy.jpg" id="lucy" ref="lucy"/>
      </div>
      <div class="picturesMobile">
        <img src="../assets/contact/daley.jpg" id="daley" ref="daleyMobile"/>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import { tween, styler} from 'popmotion';
import posed from 'vue-pose';

export default {
  name: 'Contact',
  components: {
    Navbar,
    Resume: posed.a({
      init: {
        marginTop: '20px',
      },
      visible: { opacity: 1, x: 0, scale: 1},
      hidden: { opacity: 0, x: 50 },
      hoverable: true,
      hover: { scale: 1.05}
    }),
  },
  data() {
    return {
      animateNavbar: false,
      isVisible: false,
    }
  },
  beforeRouteEnter(to, from, next) {
    //gets the previous page so we know whether to animate
    next(vm => {
      vm.prevRoute = from.path
    })
  },
  mounted() {

    setInterval(() => {
      this.isVisible = true;
    }, 1500);


    //animates navbar if coming from entry page or new window
    let n = styler(this.$refs.n)
    if ([undefined, '/'].includes(this.prevRoute)) {
      tween({
        from: {
          opacity: 0,
          y: '50px',
        },
        to: {
          opacity: 1,
          y: '0px',
        },
        duration: 500,
      }).start(n.set)
    }

    let e = styler(this.$refs.e)
    tween({
      from: {
        opacity: 0,
        x: '50px',
      },
      to: {
        opacity: 1,
        x: '0px',
      },
      elapsed: -300,
      duration: 200,
    }).start(e.set)

    let p = styler(this.$refs.p)
    tween({
      from: {
        opacity: 0,
        x: '50px',
      },
      to: {
        opacity: 1,
        x: '0px',
      },
      elapsed: -600,
      duration: 200,
    }).start(p.set)

    let l = styler(this.$refs.l)
    tween({
      from: {
        opacity: 0,
        x: '50px',
      },
      to: {
        opacity: 1,
        x: '0px',
      },
      elapsed: -900,
      duration: 200,
    }).start(l.set)

    let g = styler(this.$refs.g)
    tween({
      from: {
        opacity: 0,
        x: '50px',
      },
      to: {
        opacity: 1,
        x: '0px',
      },
      elapsed: -1200,
      duration: 200,
    }).start(g.set)

    let daley = styler(this.$refs.daley)
    tween({
      from: {
        opacity: 0,
      },
      to: {
        opacity: 1,
      },
      elapsed: -1000,
      duration: 1000,
    }).start(daley.set)

    let daleyMobile = styler(this.$refs.daleyMobile)
    tween({
      from: {
        opacity: 0,
      },
      to: {
        opacity: 1,
      },
      elapsed: -2000,
      duration: 1000,
    }).start(daleyMobile.set)

    let lucy = styler(this.$refs.lucy)
    tween({
      from: {
        opacity: 0,
      },
      to: {
        opacity: 1,
      },
      elapsed: -2000,
      duration: 1000,
    }).start(lucy.set)
  },

}
</script>

<style scoped>

  [v-cloak] {
    display: none;
  }

  .pageBody {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 0 30px 30px 30px;
    overflow-x: hidden;
  }

  .picturesDesktop {
    display:none;
    position: relative;
    width: 40vw;
    margin-right: 30px;
    max-width: 100%;
  }

  .picturesMobile {
    position: relative;
    max-width: 80vw;
  }

  #daley {
    width: 100%;
    max-width: 40vh;
    border-radius: 8px;
    border: 2px solid honeydew;
  }

  #lucy {
    margin-top: -3vh;
    margin-left: 20vw;
    width:20vw;
    max-width: 250px;
    border-radius: 8px;
    border: 2px solid honeydew;
  }

  .detailsContainer {
    font-size: 1.5rem;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
  }

  .linkText {
    color: white;
    color: honeydew;
    text-decoration: none;
    margin: 10px 0;
    white-space: nowrap;
  }

  .viewResume {
    border: none;
    padding: 10px 25px;
    color: var(--background-color);
    text-decoration: none;
    font-size: 1rem;
    font-weight: 800;
    background: honeydew;
    border-radius: 5px;
    text-align: center;
    white-space: nowrap;
    cursor: pointer;
    width: 100%;
  }

  .viewResume:hover {
    border-radius: 0;
  }

  .contactIcon {
    margin-right: 15px;
    font-size: 1.3rem;
  }

  @media screen and (min-width: 650px) {
    .pageBody {
      flex-direction: row;
      align-items: center;
    }

    #daley {
      width: 35vw;
    }

    .picturesMobile {
      width: 35vw;
      margin: 0 20px;
    }

    .detailsContainer {
      margin: 0 20px;
    }
  }

  @media screen and (min-width: 900px) {
    .picturesDesktop {
      display: block;
    }

    #daley {
       margin-top: 5vh;
    }

    .picturesMobile {
      display: none;
    }

  }


</style>