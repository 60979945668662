<template>
  <div  class="projectContainer">
    <div class="imgContainer">
      <img :src="require(`@/assets/software/${project.imageSource}`)" alt="app screenshot" :style="project.other ? project.other : ''" :class="'screenshot ' + (project.link ? 'clickableScreenshot' : '')"  @click="openLink"/>
    </div>
    <div class="detailsContainer">
      <div class="projectTitle">{{project.title}}</div>
      <div class="projectSubtitle">{{project.subtitle}}</div>
      <div class="projectBody">{{project.body}}</div>
      <a v-if="project.link" class="linkButton" target=_blank :href="project.link">TRY IT HERE</a>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SoftwareTile',
  props: ['project'],
  methods: {
    openLink() {
      if (this.project.link) {
        window.open(this.project.link, '_blank');
      }
    }
  }
}
</script>

<style scoped>

  .projectContainer {
    border-radius: 8px;
    margin: 30px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 8px;
  }

  .screenshot {
    width: 100%;
    height: auto;
    border-radius: 5px;
    object-fit: contain;
    transition: 100ms;
  }

  .clickableScreenshot:hover {
    cursor: pointer;
    transform: scale(1.01);
  }

  .detailsContainer {
    padding: 20px 0;
    display: flex;
    flex-direction: column;
  }

  .projectTitle {
    color: white;
    font-size: 2rem;
  }

  .projectSubtitle {
    color: var(--accent-orange);
    font-style: italic;
    font-weight: 400;
    font-size: 1.3rem;
    margin-top: 5px;
  }

  .projectBody {
    color: white;
    margin: 20px 0;
    font-size: 1.3rem;
    line-height: 1.3;
  }

  .linkButton {
    padding: 10px 25px;
    color: white;
    text-decoration: none;
    font-weight: 800;
    background: linear-gradient(135deg, rgba(253,29,29,1) 0%, rgba(252,176,69,1) 100%);
    border-radius: 5px;
    margin-top: auto;
    width: 180px;
    text-align: center;
    transition: 300ms;
  }

  .linkButton:hover {
    transform: scale(1.05);
    border-radius: 0;
  }

  @media screen and (min-width: 900px) {
    .projectContainer {
      margin: 40px 20px;
      flex-direction: row;
    }

    .detailsContainer {
      padding: 0 20px 10px 20px;
    }

    .screenshot {
      width: 40vw;
    }
  }

</style>
