<template>
  <div class="projectContainer" :style="{ 'background-image': 'url(' + require(`/src/assets/furniture/thumbnail/${project.afterPath}`) + ')'}" @click="emitSelected" >
  </div>
</template>

<script>

export default {
  name: 'FurnitureTile',
  props: ['project', 'index'],
  methods: {
    emitSelected() {
      this.$emit('selected', 'furniture', this.index)
    }
  },
  data() {
    return {
      showDetails: false
    }
  }
}
</script>

<style scoped>

  .projectContainer {
    margin: 10px;
    padding: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 300ms;
    background-size: 300px;
    background-position: center center;
    background-repeat: no-repeat;
    min-height: 150px;
  }

  .projectContainer:hover {
    cursor: pointer;
    transform: scale(1.05);
  }

  .detailsContainer {
    padding: 20px 0;
    display: flex;
    flex-direction: column;
  }

  .projectTitle {
    color: white;
    font-weight: 800;
    font-size: 1.5rem;
    text-shadow: 3px 3px 10px black;
    text-align: center;
  }

  .projectSubtitle {
    color: var(--accent-orange);
    font-style: italic;
    font-weight: 400;
    font-size: 1.3rem;
    margin-top: 5px;
  }

  .projectBody {
    color: white;
    margin: 20px 0;
    font-size: 1.3rem;
    line-height: 1.3;
  }

  .linkButton {
    padding: 10px 25px;
    color: white;
    text-decoration: none;
    font-weight: 800;
    background: linear-gradient(135deg, rgba(253,29,29,1) 0%, rgba(252,176,69,1) 100%);
    border-radius: 5px;
    margin-top: auto;
    width: 180px;
    text-align: center;
    transition: 300ms;
  }

  .linkButton:hover {
    transform: scale(1.05);
    border-radius: 0;
  }

  @media screen and (min-width: 900px) {
    .projectContainer {
      flex-direction: row;
      min-height: 150px;
    }

    .projectTitle {
      font-size: 2rem;
    }

    .detailsContainer {
      padding: 10px 20px 10px 20px;
    }
  }

</style>
