<template>
  <div class="pageContainer">
    <div ref="n">
      <Navbar />
    </div>
    <div class="pageBody" v-cloak>
      <AboutTextContainerAnimated class="textContainer" :pose="isVisible ? 'visible' : 'hidden'">
        <AboutTextAnimated class="heading">Hey, I'm Austin</AboutTextAnimated>
        <AboutTextAnimated>I'm a software engineer currently living in St. Louis.</AboutTextAnimated>
        <AboutTextAnimated>I was born in Maui, HI, and lived there until the age of 9, when I moved to a suburb of Austin, TX. As a child I developed a strong passion for creating things, both physical and digital. When I was 13 I ripped the carpet out of my playroom (with my parents' reluctant approval), and turned it into a small workshop where I built various projects over the next several years. I also loved making home movies, and spent countless hours teaching myself editing software and researching filmmaking techniques. That became my primary interest, and so after high school I moved to Boston, MA to study film production at Emerson College. </AboutTextAnimated>
        <AboutTextAnimated>I had just over a semester of college remaining when the COVID-19 pandemic hit, and in the span of a day all my classes went online and the restaurant I worked at shut down. Having a lot of free time on my hands, I began to learn JavaScript alongside my dad (a retired programmer who was learning it as a hobby), and quickly realized coding was one of the most versatile means of creation I'd seen, and something I really enjoyed doing. I spent the rest of my time at college developing personal projects in my spare time.</AboutTextAnimated>
        <AboutTextAnimated>After graduating I moved to St. Louis, MO with my girlfriend Daley, and decided to pursue programming professionally. I used everything I'd saved during college to attend a 3 month intensive coding bootcamp, where I filled in the gaps of my JavaScript knowledge and gained experience working on larger projects as part of a team. Since then, I've worked remotely as a full stack engineer.</AboutTextAnimated>
      </AboutTextContainerAnimated>
      <div class="pictures" ref="aboutPhoto">
        <div id="imageCaption">Liberty Island</div>
        <div class="imgContainer">
          <img src="../assets/about/snake.jpg" id="aboutPhotoUnder" />
          <img src="../assets/about/snake.jpg" id="aboutPhoto"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import { tween, styler} from 'popmotion';
import posed from 'vue-pose';

export default {
  name: 'About',
  components: {
    Navbar,
    AboutTextContainerAnimated: posed.div({
      visible: {
        delayChildren: 300,
        staggerChildren: 250,
      }
    }),
    AboutTextAnimated: posed.p({
      visible: { opacity: 1, y: 0  },
      hidden: { opacity: 0, y: 10 }
    })
  },
  data() {
    return {
      animateNavbar: false,
      isVisible: false,
      currPhotoIndex: 0,
      imageFadeIntervalId: null
    }
  },
  beforeRouteEnter(to, from, next) {
    //gets the previous page so we know whether to animate
    next(vm => {
      vm.prevRoute = from.path
    })
  },
  mounted() {
    setInterval(() => {
      this.isVisible = true;
    }, 0);

    const photosSrcArray = [
      {
        path: require(`/src/assets/about/snake.jpg`),
        caption: 'Liberty Island',
      },{
        path: require(`/src/assets/about/budapest.jpg`),
        caption: 'Budapest',
      },{
        path: require(`/src/assets/about/django.jpg`),
        caption: 'with Django',
      },{
        path: require(`/src/assets/about/maui.jpg`),
        caption: 'Iao Valley',
      },{
        path: require(`/src/assets/about/daley.jpg`),
        caption: 'with Daley',
      },{
        path: require(`/src/assets/about/boston.jpg`),
        caption: 'Boston',
      }]

    this.imageFadeIntervalId = setInterval(() => {
      this.currPhotoIndex = this.currPhotoIndex === photosSrcArray.length - 1 ? 0 : this.currPhotoIndex + 1
      const top = document.getElementById('aboutPhoto')
      const bottom = document.getElementById('aboutPhotoUnder')
      const caption = document.getElementById('imageCaption')
      bottom.setAttribute('src', photosSrcArray[this.currPhotoIndex].path)
      top.classList.add('dissolve')
      caption.classList.add('dissolve')
      setTimeout(() => {
        caption.innerHTML = photosSrcArray[this.currPhotoIndex].caption
        caption.classList.remove('dissolve')
      }, 500)
      setTimeout(() => {
        top.setAttribute('src', photosSrcArray[this.currPhotoIndex].path)
        top.classList.remove('dissolve')
      }, 1000)
    }, 5000)

    //animates navbar if coming from entry page or new window
    let n = styler(this.$refs.n)
    if ([undefined, '/'].includes(this.prevRoute)) {
      tween({
        from: {
          opacity: 0,
          y: '50px',
        },
        to: {
          opacity: 1,
          y: '0px',
        },
        duration: 500,
      }).start(n.set)
    }

    let aboutPhoto = styler(this.$refs.aboutPhoto)
    tween({
      from: {
        opacity: 0,
      },
      to: {
        opacity: 1,
      },
      elapsed: -1000,
      duration: 1000,
    }).start(aboutPhoto.set)
  },
  beforeDestroy() {
    clearInterval(this.imageFadeIntervalId)
  },
}
</script>

<style scoped>

  [v-cloak] {
    display: none;
  }

  .pageBody {
    display: flex;
    justify-content: space-evenly;
    padding: 30px;
    overflow-x: hidden;
    min-height: 800px;
  }

  .textContainer {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    margin-left: 30px;
  }

  .heading {
    color: white;
    font-size: 2rem;
    margin-bottom: -10px;
    margin-top: 0;
  }

  p {
    color: white;
    font-size: 1.3rem;
    line-height: 1.3;

  }

  .pictures {
    display:none;
    position: relative;
    width: 40vw;
    margin-right: 30px;
    max-width: 100%;
    margin-left: 10vw;
  }

  .imgContainer {
    width: 30vw;
    max-width: 400px;
  }

  #aboutPhoto, #aboutPhotoUnder {
    border-radius: 8px;
    position: absolute;
    max-width: 400px;
    border: 2px solid honeydew;
    width: 100%;
  }

  #aboutPhoto, #imageCaption {
    opacity: 1;
    transition: opacity 1s ease-in-out;
    -webkit-transition: opacity 1s ease-in-out;
    -moz-transition: opacity 1s ease-in-out;
    -o-transition: opacity 1s ease-in-out;
  }

  #aboutPhoto.dissolve, #imageCaption.dissolve  {
    opacity: 0;
  }

  #imageCaption {
    color: white;
    font-style: italic;
    margin: 5px;
    transition: opacity 500ms ease-in-out;
    -webkit-transition: opacity 500ms ease-in-out;
    -moz-transition: opacity 500ms ease-in-out;
    -o-transition: opacity 500ms ease-in-out;
  }

  @media screen and (min-width: 650px) {
    .pictures {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }


</style>