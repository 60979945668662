<template>
  <div id="app">
    <div id="nav">
      <router-link to="/" class="homeButton">austin elwell</router-link>
      <div id='navLinksContainer'>
        <router-link class="navLink" to="/software">software</router-link>
        <router-link class="navLink" to="/projects">projects</router-link>
        <router-link class="navLink" to="/about">about</router-link>
        <router-link class="navLink" to="/contact">contact</router-link>
      </div>
      <div id='miniNavLinksContainer'>
        <router-link to="/software"><font-awesome-icon icon="code" /></router-link>
        <router-link to="/projects"><font-awesome-icon icon="hammer" /></router-link>
        <router-link to="/about"><font-awesome-icon icon="user" /></router-link>
        <router-link to="/contact"><font-awesome-icon icon="envelope" /></router-link>
      </div>
    </div>

    <div class="pageContainer">
      <router-view/>
    </div>
  </div>
</template>


<script>
export default {
  name: 'Navbar',
}
</script>

<style scoped>

body {
  margin: 0;
  padding: 0;
  background-color: var(--background-color);
}

#app {
  font-family: 'Urbanist', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.homeButton {
  display: none;
}

#nav {
  border: double 3px transparent;
  background-image: linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 50%, rgba(252,176,69,1) 100%);;
  background-origin: border-box;
  background-clip: content-box, border-box;
  margin: 30px;
  height: 70px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  overflow: hidden;
}

#nav a {
  font-size: 2rem;
  font-weight: 200;
  color: black;
  text-decoration: none;
}

#nav a.router-link-active {
  color: white;
}

#navLinksContainer {
  display: none;
}

.navLink {
  transition: 200ms;
}

.navLink:hover {
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

#miniNavLinksContainer {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

@media screen and (min-width: 650px) {
  .homeButton {
    display: flex;
    padding-left: 25px;
    font-size: 2rem;
    font-weight:500 !important;
    color: var(--background-color) !important;
    margin-right: 75px;
    white-space: nowrap;
  }

  #navLinksContainer, #miniNavLinksContainer {
    margin-left: auto;
  }

  #miniNavLinksContainer {
    justify-content: right;
  }

  #navLinksContainer a {
    margin: 0 50px 0 auto;
  }

  #miniNavLinksContainer a {
    margin: 0 50px 0 auto;
  }
}

@media screen and (min-width: 900px) {
  #miniNavLinksContainer {
    display: none;
  }
  #navLinksContainer {
    display: flex;
  }
}

</style>

