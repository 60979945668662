import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Software from '../views/Software.vue'
import Projects from '../views/Projects.vue'
import About from '../views/About.vue'
import Contact from '../views/Contact.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/', component: Home },
  { path: '/software', component: Software },
  { path: '/projects*', component: Projects },
  { path: '/about', component: About },
  { path: '/contact', component: Contact },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

export default router
