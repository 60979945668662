<template>
  <div class="projectsPageMasterContainer">
    <Modal v-if="showModal" :tab="tab" :project="activeProject" :index="activeProjectIndex" :showNext="activeProjectIndex === projects[tab].length - 1 ? null : showNext" :showPrev="activeProjectIndex === 0 ? null : showPrev" @closeModal="showModal = false" />
    <div ref="n">
      <Navbar />
    </div>
    <ProjectsTileContainerAnimated class="mobilePageContainer" :pose="isVisible ? 'visible' : 'hidden'" :style="showModal ? 'position:fixed;' : ''">

        <ProjectsTileAnimated>
          <div class="mobileSectionHeader" @click="tab = tab === 'film' ? null : 'film'"><div>film</div><font-awesome-icon :icon="tab==='film' ? 'caret-up' : 'caret-down'" class="caretIcon"/></div>
        </ProjectsTileAnimated>
        <div v-if="tab==='film'" class="allProjects">
          <ProjectsTileAnimated v-for="(project, index) in projects.film" :key="tab + index">
            <FilmTile :project="project" :index="index" @selected="setActiveProject" />
          </ProjectsTileAnimated>
        </div>

        <ProjectsTileAnimated>
          <div class="mobileSectionHeader" @click="tab = tab === 'photos' ? null : 'photos'"><div>photography</div><font-awesome-icon :icon="tab==='photos' ? 'caret-up' : 'caret-down'" class="caretIcon"/></div>
        </ProjectsTileAnimated>
        <div v-if="tab==='photos'" class="allProjects">
          <ProjectsTileAnimated v-for="(project, index) in projects.photos" :key="tab + index">
            <PhotosTile  :project="project" :index="index" @selected="setActiveProject" />
          </ProjectsTileAnimated>
        </div>

      <ProjectsTileAnimated>
        <div class="mobileSectionHeader" @click="tab = tab === 'furniture' ? null : 'furniture'"><div>furniture</div><font-awesome-icon :icon="tab === 'furniture' ? 'caret-up' : 'caret-down'" class="caretIcon"/></div>
      </ProjectsTileAnimated>
      <div v-if="tab==='furniture'" class="allProjects">
        <ProjectsTileAnimated v-for="(project, index) in projects.furniture" :key="tab + index" >
          <FurnitureTile :project="project" :index="index" @selected="setActiveProject" />
        </ProjectsTileAnimated>
      </div>

      <!-- <ProjectsTileAnimated>
        <div class="mobileSectionHeader" @click="tab = tab === 'wood' ? null : 'wood'"><div>wood/metal</div><font-awesome-icon :icon="tab === 'wood' ? 'caret-up' : 'caret-down'" class="caretIcon"/></div>
      </ProjectsTileAnimated>
      <div v-if="tab==='wood'" class="allProjects">
        <ProjectsTileAnimated v-for="(project, index) in projects.wood" :key="tab + index" >
          <WoodTile :project="project" :index="index" @selected="setActiveProject" />
        </ProjectsTileAnimated>
      </div> -->

    </ProjectsTileContainerAnimated>

    <div class="desktopPageContainer" :style="showModal ? 'position:fixed;' : ''">
      <div class="sidebarHidden">
        <div class="sidebarOption">photography</div>
      </div>
      <div class="sidebar" ref="sidebar">
        <router-link to="/projects/film" class="sidebarOption">film</router-link>
        <router-link to="/projects/photos" class="sidebarOption">photography</router-link>
        <router-link to="/projects/furniture" class="sidebarOption">furniture</router-link>
        <!-- <router-link to="/projects/wood" class="sidebarOption">wood/metal</router-link> -->
      </div>
      <ProjectsTileContainerAnimated v-if="tab==='film'" class="allProjects" :pose="isVisible ? 'visible' : 'hidden'">
          <ProjectsTileAnimated v-for="(project, index) in projects.film" :key="tab + index" class="individualGridElement">
            <FilmTile :project="project" :index="index" @selected="setActiveProject" />
          </ProjectsTileAnimated>
      </ProjectsTileContainerAnimated>

      <ProjectsTileContainerAnimated v-if="tab==='photos'" class="allProjects" :pose="isVisible ? 'visible' : 'hidden'">
        <ProjectsTileAnimated v-for="(project, index) in projects[tab]" :key="tab + index" >
           <PhotosTile  :project="project" :index="index" @selected="setActiveProject" />
        </ProjectsTileAnimated>
      </ProjectsTileContainerAnimated>

      <ProjectsTileContainerAnimated v-if="tab==='furniture'" class="allProjects" :pose="isVisible ? 'visible' : 'hidden'">
        <ProjectsTileAnimated v-for="(project, index) in projects[tab]" :key="tab + index" >
          <FurnitureTile :project="project" :index="index" @selected="setActiveProject" />
        </ProjectsTileAnimated>
      </ProjectsTileContainerAnimated>

      <!-- <ProjectsTileContainerAnimated v-if="tab==='wood'" class="allProjects" :pose="isVisible ? 'visible' : 'hidden'">
        <ProjectsTileAnimated v-for="(project, index) in projects[tab]" :key="tab + index" >
          <WoodTile :project="project" :index="index" @selected="setActiveProject" />
        </ProjectsTileAnimated>
      </ProjectsTileContainerAnimated> -->
    </div>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Modal from '@/components/Modal.vue'
import FilmTile from '@/components/FilmTile.vue'
import PhotosTile from '@/components/PhotosTile.vue'
import FurnitureTile from '@/components/FurnitureTile.vue'
// import WoodTile from '@/components/WoodTile.vue'

import { tween, styler} from 'popmotion';
import posed from 'vue-pose';

export default {
  name: 'Projects',
  components: {
    Navbar,
    Modal,
    FilmTile,
    PhotosTile,
    FurnitureTile,
    // WoodTile,
    ProjectsTileContainerAnimated: posed.div({
      visible: {
        delayChildren: 300,
        staggerChildren: 200,
      },
      hidden: {
        delayChildren: 0,
        staggerChildren: 0,
      }
    }),
    ProjectsTileAnimated: posed.div({
      visible: { opacity: 1, y: 0  },
      hidden: { opacity: 0, y: 25 }
    }),
  },
  data() {
    return {
      prevRoute: null,
      tab: null,
      isVisible: false,
      showModal: false,
      activeProject: null,
      activeProjectIndex: null,
      projects: {
        film: [
          {
            title: 'fast talk',
            subtitle: '2020 / 3min',
            body: `This music video was a passion project that allowed me to explore all of the the creative possiblities that my motion graphics skills allowed for.`,
            link: 'https://www.youtube.com/embed/NSZNUepCbLY',
            size: 'widescreen',
            thumbnail: 'fasttalk.jpg',
          },
          {
            title: 'there it is',
            subtitle: '2020 / 2min',
            body: `This motion graphics project was inspired by the poem "There It Is" by Jayne Cortez. Each line's animation is intended to match its theme.`,
            link: 'https://www.youtube.com/embed/3capzEykkDQ',
            size: 'normal',
            extra: 'https://www.poetryfoundation.org/poems/90805/there-it-is',
            thumbnail: 'thereitis.jpg',
          },
          {
            title: 'coordinate plane',
            subtitle: '2020 / 2min',
            body: `This motion graphics project was an attempt to use different techniques and animations to visualize a specific song.`,
            link: 'https://www.youtube.com/embed/yBhUngk5VAA',
            size: 'normal',
            thumbnail: 'coordinate.jpg',
          },
          {
            title: 'directing: final project',
            subtitle: '2019 / 4min',
            body: `This film was my final project for my directing course at Emerson College. The assignment: show a complete scene with a beginning, middle, and end using no dialogue.`,
            link: 'https://www.youtube.com/embed/n5UMn2q3HqQ',
            size: 'widescreen',
            thumbnail: 'directing.jpg',
          },
          {
            title: 'short personal documentary',
            subtitle: '2020 / 3min',
            body: `This personal documentary was made for my advanced cinematography course at Emerson College. After in-person classes were cancelled due to the Covid-19 crisis, we were tasked with making a documentary about our current situation. Trying to stay positive, mine focuses on my family and Maui, the island I grew up on.`,
            link: 'https://www.youtube.com/embed/nu238VPXb5I',
            size: 'widescreen',
            thumbnail: 'documentary.jpg',
          },
          // {
          //   title: 'caress your soul',
          //   subtitle: '2019 / 3min',
          //   body: `	This music video was made as part of a 24 hour challenge. Special thanks to my parents who graciously offered to star in it on short notice.`,
          //   link: 'https://www.youtube.com/embed/3jzC1ogtfN4',
          //   size: 'widescreen',
          //   thumbnail: 'caress.jpg',
          // },
          {
            title: 'the intelligent life of martin',
            subtitle: '2018 / 8min',
            body: `This is a short mockumentary film about Martin, a man on a mission to prove his worth and win his ex-girlfriend back.`,
            link: 'https://www.youtube.com/embed/fliwALVNTMY',
            size: 'normal',
            thumbnail: 'martin.jpg',
          },
          {
            title: 'atx',
            subtitle: '2017 / 3min',
            body: `This is a short video attempting to highlight the culture of downtown Austin, TX.`,
            link: 'https://www.youtube.com/embed/Ux-4acCer-0',
            size: 'normal',
            thumbnail: 'atx.jpg',
          },
        ],
        photos: [
          {
            title: 'Boston, MA',
            path: 'boston.jpg',
          },
          {
            title: 'Krabi, Thailand',
            path: 'krabi.jpg',
          },
          {
            title: 'Da Nang, Vietnam',
            path: 'daNang.jpg',
          },
          {
            title: 'Da Nang, Vietnam',
            path: 'daNang2.jpg',
          },
          {
            title: 'Ho Chi Minh City, Vietnam',
            path: 'hochiminh.jpg',
          },
          {
            title: 'Trieste, Italy',
            path: 'trieste.jpg',
          },
          {
            title: 'Dolomite Alps, Italy',
            path: 'dolomites.jpg',
          },
          // {
          //   title: 'Niagara Falls, Canada',
          //   path: 'niagara.jpg',
          // },
          {
            title: 'Haleakala, Maui',
            path: 'haleakala.jpg',
          },
          {
            title: 'Paia, Maui',
            path: 'maui.jpg',
          },
          {
            title: 'Wailea, Maui',
            path: 'wailea.jpg',
          },
          {
            title: 'Kihei, Maui',
            path: 'kihei.jpg',
          },
          {
            title: 'Flamenco Beach, Puerto Rico',
            path: 'puertoRico.jpg',
          },
          {
            title: 'Kansas City, MO',
            path: 'kansasCity.jpg',
          },
          {
            title: 'Saint Louis, MO',
            path: 'stl.jpg',
          },
          {
            title: 'Seattle, WA',
            path: 'seattle.jpg',
          },
          {
            title: 'Seattle, WA',
            path: 'seattle2.jpg',
          },
        ],
       furniture: [
          {
            beforePath: 'before1.jpg',
            afterPath: 'after1.jpg',
          },
          {
            beforePath: 'before2.jpg',
            afterPath: 'after2.jpg',
          },
          {
            beforePath: 'before3.jpg',
            afterPath: 'after3.jpg',
          },
          {
            beforePath: 'before4.jpg',
            afterPath: 'after4.jpg',
          },
        ],
        wood: [

        ],
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    //gets the previous page so we know whether to animate
    next(vm => {
      vm.prevRoute = from.path
    })
  },
  mounted() {
    this.initTab()
    setTimeout(() => {
      this.isVisible = true;
    }, 0);
    //animates navbar if coming from entry page or new window
    let n = styler(this.$refs.n)
    if ([undefined, '/'].includes(this.prevRoute)) {
      tween({
        from: {
          opacity: 0,
          y: '50px',
        },
        to: {
          opacity: 1,
          y: '0px',
        },
        duration: 500,
      }).start(n.set)
    }

    let sidebar = styler(this.$refs.sidebar)
    tween({
      from: {
        opacity: 0,
        y: '50px',
      },
      to: {
        opacity: 1,
        y: '0px',
      },
      elapsed: -300,
      duration: 300,
    }).start(sidebar.set)

  },
  methods: {
    initTab() {
      if (!this?.$root?._route?.params?.pathMatch || !['/film', '/photos', '/furniture', '/wood'].includes(this?.$root?._route?.params?.pathMatch)) {
        this.$router.push(`/projects/film`)
      } else {
        this.isVisible = false
        this.tab = this.$root._route.params.pathMatch.slice(1)
        setTimeout(() => {
          this.isVisible = true;
        }, 0);
      }
    },
    setActiveProject(tab, idx) {
      let project = this.projects[tab][idx]
      if (project) {
        this.activeProject = project
        this.activeProjectIndex = idx
        this.showModal = true
      }
    },
    showNext() {
      this.setActiveProject(this.tab, this.activeProjectIndex+1)
    },
    showPrev() {
      this.setActiveProject(this.tab, this.activeProjectIndex-1)
    }
  },
  watch: {
    $route(to) {
      if (to.params?.pathMatch && ['/film', '/photos', '/furniture', '/wood'].includes(to.params?.pathMatch)) {
        this.isVisible = false
        this.tab = to.params.pathMatch.slice(1)
        setTimeout(() => {
          this.isVisible = true;
        }, 0);
      } else {
        this.$router.push(`/projects/film`)
      }
    }
  }
}
</script>

<style scoped>

  .desktopPageContainer {
    display:none;
  }

  .allProjects {
    width: 100%;
    display: grid;
    margin-right: 30px;
    grid-template-columns: repeat( auto-fit, minmax(300px,1fr) );
    justify-items: stretch;
  }

  .individualGridElement {
    width: 100%;
  }

  .caretIcon {
    margin-left:auto;
    margin-right: 10px;
  }

  .mobilePageContainer {
    margin: 0 30px;
    /* overflow-y: scroll;
    overflow-x: hidden; */
  }

  .mobileSectionHeader {
    color: var(--background-color);
    background: honeydew;
    margin: 10px 0px;
    padding: 5px;
    border-radius: 5px;
    font-size: 1.3rem;
    display: flex;
    align-items: center;
  }

  .sidebar {
    position: sticky;
    width: fit-content;
    padding: 0 10px;
    margin: 0 30px;
    display: flex;
    flex-direction: column;
  }

  .sidebarHidden {
    position: absolute;
    width: fit-content;
    padding: 0 10px;
    margin: 30px;
    cursor: auto;
    /* border-right: 2px solid honeydew; */
  }

  .sidebarHidden div {
    opacity: 0%;
  }

  .sidebarHidden .sidebarOption {
    cursor: default;
  }

  .sidebarOption {
    color: honeydew;
    font-size: 1.5rem;
    margin-bottom: 10px;
    cursor: pointer;
    padding: 5px;
    padding-right: 50px;
    text-decoration: none;
    border-radius: 3px;
    transition: 200ms;
  }

  .sidebar a.router-link-active {
    color: var(--background-color);
    background: honeydew;
  }

  .sidebarOption:hover {
    margin-left: 10px;
    margin-right: -10px;
  }

  @media screen and (min-width: 900px) {
    .desktopPageContainer {
      display: flex;
      width: 100%;
    }

    .mobilePageContainer {
      display: none;
    }
  }



</style>