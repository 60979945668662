<template>
  <div class="pageContainer">
    <div ref="n">
      <Navbar />
    </div>
    <SoftwareTileContainerAnimated class="allProjects" :pose="isVisible ? 'visible' : 'hidden'">
      <SoftwareTileAnimated v-for="project in softwareProjects" :key="project.title" >
        <SoftwareTile :project="project"/>
      </SoftwareTileAnimated>
    </SoftwareTileContainerAnimated>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import SoftwareTile from '@/components/SoftwareTile.vue'
import { tween, styler} from 'popmotion';
import posed from 'vue-pose';



export default {
  name: 'Software',
  components: {
    Navbar,
    SoftwareTile,
    SoftwareTileContainerAnimated: posed.div({
      visible: {
        delayChildren: 300,
        staggerChildren: 200,
      }
    }),
    SoftwareTileAnimated: posed.div({
      visible: { opacity: 1, y: 0  },
      hidden: { opacity: 0, y: 50 }
    })
  },
  data() {
    return {
      isVisible: false,
      prevRoute: null,
      softwareProjects: [
        {
          title: 'this website',
          subtitle: 'js / vue / css',
          body: `Maybe a bit meta, but building this site from scratch is one of the more intricate personal projects I've done. Using vue router for navigation and popmotion for transitions, this SPA gave me a chance to work on creating a fluid and mobile-friendly UX, and forced me to spend far too long tweaking CSS. Click the goblin on the homepage if you feel so inclined. `,
          imageSource: 'website.jpg',
        },
        {
          title: 'notes app',
          subtitle: 'js / react / css',
          body: `To make this site more intersting than a normal CRUD app, I wanted to add as much real-world functionality as possible. In this case, that meant adding features like password-protected notes, a search UI that saves any changes made during a search, and automatic scrolling to any note.`,
          link: 'https://notes.austinelwell.com',
          imageSource: 'notes.jpg',
        },
        {
          title: 'the reel list',
          subtitle: 'js / react / css',
          body: `This project was created for the film lover in me, who wanted a way to search for, read about, and sort my favorite films. Utilizing the Open Movie Database API, this app displays detailed info about any movie. In addition, it allows users to add films to a watchlist or 'my films' list and rate them on a scale of one to five.`,
          link: 'https://reel.austinelwell.com',
          imageSource: 'reel.jpg',
        },
        {
          title: 'bookmarks directory',
          subtitle: 'js / react / css',
          body: `This program was made as a challenge to work out the logistics of nested folders and recursive components. It allows users to create a directory similar to the native bookmark UI of several browsers, where you can add or delete links and folders.`,
          link: 'https://bookmarks.austinelwell.com',
          imageSource: 'bookmarks.jpg',
        },
        {
          title: 'time calculator',
          subtitle: 'js / react / css',
          body: `This idea for this project formed as I was doing an online coding puzzle, which asked me to convert a given amount of seconds into certain units. I created an app that, given a date, will tell you exactly how long it has been since (or until) that date, in the unit of your choice. Complete with randomized loading screens, it updates every second.`,
          link: 'https://time.austinelwell.com',
          imageSource: 'time.jpg',
        },
        {
          title: 'maze solver',
          subtitle: 'js / html / css',
          body: `This project was inspired by a youtube tutorial, but then became so much more. As one of my first JavaScript projects, I customized it to include several additional pieces of functionality such as the animation aspect of the maze solution, the ability to change the starting and ending points, and the numerous theme options. Just draw some walls and watch as the computer solves the maze.`,
          link: 'https://maze.austinelwell.com',
          imageSource: 'maze.jpg',
        },
      ]
    }
  },
  beforeRouteEnter(to, from, next) {
    //gets the previous page so we know whether to animate
    next(vm => {
      vm.prevRoute = from.path
    })
  },
  mounted() {
    setTimeout(() => {
      this.isVisible = true;
    }, 0);
    //animates navbar if coming from entry page or new window
    let n = styler(this.$refs.n)
    if ([undefined, '/'].includes(this.prevRoute)) {
      tween({
        from: {
          opacity: 0,
          y: '50px',
        },
        to: {
          opacity: 1,
          y: '0px',
        },
        duration: 500,
      }).start(n.set)
    }
  },
}

</script>

<style scoped>

  .pageContainer {
    height: 100%;

  }

  .allProjects {
    margin: 50px;
  }

</style>